import React from 'react';
import '../popup.css';
import { H2 } from './style';
import { Button, Title2 } from '../styledComponents';
import Modal from '../Modal';

class Modals extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { showModal } = this.state;
    return (
      <div>
        <Modal show={showModal}>
          <div>
            <Title2>
              Choix des retouches pour Robe
            </Title2>
            <Button onClick={() => this.setState({ showModal: false })}>
              Fermer
            </Button>
          </div>
        </Modal>
        <H2>Modal et popup</H2>
        <div>
          <Button onClick={() => this.setState({ showModal: true })}>
            Ouvrir modal
          </Button>
        </div>
      </div>
    );
  }
}

export default Modals;
