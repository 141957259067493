import React from 'react';
import styled from 'styled-components';
import './font.css';
import { H2, H3 } from './style';
import Dots from '../home/v2/Dots';
import {
  colors, fontSizes, fontWeight, Title1, Title2, Title3, Column, Subtitle2, EmphasisText,
}
  from '../home/v2/styledComponents';

const LibreBaskerville = styled.div`font-family: 'Libre Baskerville';`;
const Roboto = styled.div`font-family: 'Roboto';`;

const P = styled.p`font-weight: 300; margin-left: 20px; font-size: 30px;`;
const Regular = styled(P)`font-weight: ${fontWeight.regular};`;
const Medium = styled(P)`font-weight: ${fontWeight.medium};`;
const Bold = styled(P)`font-weight: bold;`;

const S = styled.p`font-size: ${fontSizes.s}`;
const M = styled.p`font-size: ${fontSizes.m}`;
const L = styled.p`font-size: ${fontSizes.l}`;
const XL = styled.p`font-size: ${fontSizes.xl}`;
const X2L = styled.p`font-size: ${fontSizes.x2l}`;
const X3L = styled.p`font-size: ${fontSizes.x3l}`;
const X4L = styled.p`font-size: ${fontSizes.x4l}`;

const Titles = styled.div`margin: 40px; width: fit-content;`;
const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => props.column ? 'column' : 'row'};
  border: solid 1px ${colors.navy};
  padding: 10px;
  margin: 10px;
`;

const Fonts = () => (
  <div>
    <H2>Polices</H2>

    <LibreBaskerville>
      <H3>Libre Baskerville</H3>
      <Medium>Libre Baskerville // Utilisation : sous titres</Medium>
      <Bold>Libre Baskerville Bold // Utilisation : titres</Bold>
    </LibreBaskerville>

    <Roboto>
      <H3>Roboto</H3>
      <Regular>Roboto Regular // Utilisation : sous titres, paragraphes</Regular>
      <Bold>Roboto Bold // Utilisation : paragraphes</Bold>
    </Roboto>

    <H2>Tailles</H2>

    <S>taille S 14px - font Regular</S>
    <M>taille M 18px - font Regular</M>
    <L>taille L 22px - font Regular</L>
    <XL>taille XL 26px - font Regular</XL>
    <X2L>taille 2XL 32px - font Regular</X2L>
    <X3L>taille 3XL 48px - font Regular</X3L>
    <X4L>taille 4XL 60px - font Regular</X4L>

    <H2>Titres</H2>

    <Titles>
      <Container>
        <Title1>Titre de niveau 1 - page</Title1>
        <br />
        <br />
      </Container>

      <Container>
        <Column justifyContent="center" alignItems="center">
          <Title2 margin="0px">Titre de niveau 2 - block</Title2>
          <Dots />
          <Subtitle2>
            Ceci est un sous titre
            {' '}
            <br />
            niveau 2 - block
          </Subtitle2>
        </Column>
      </Container>

      <Container column>
        <Title3>Titre de niveau 3 - paragraphe</Title3>
        <EmphasisText>
          Ceci est un sous titre niveau 3 - paragraphe
          <br />
          {' '}
          et bien sûr pas un sous titre niveau 28
        </EmphasisText>
      </Container>
    </Titles>
  </div>
);

export default Fonts;
